
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import {Button, Flex, Heading, Text} from '@chakra-ui/react'
import {type NextPage} from 'next'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import MainLayout from '../components/layouts/Mainlayout'
import {ROUTES_HOME} from '../constants/routes'

const Page404: NextPage = () => {
  const {t} = useTranslation('common')

  return (
    <MainLayout>
      <Flex placeItems='center' direction='column' gap={10} py={20}>
        <Flex gap={20} placeItems='center'>
          <Text fontSize={200} color='brand.red' fontWeight='bold'>
            404
          </Text>
        </Flex>
        <Heading>{t('error_404_message')}</Heading>
        <Link href={ROUTES_HOME} passHref>
          <Button variant='primary'>{t('error_page_button')}</Button>
        </Link>
      </Flex>
    </MainLayout>
  )
}

// noinspection JSUnusedGlobalSymbols
export default Page404


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/404',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  